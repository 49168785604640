import { BACKEND } from '../secrets/config';
import { DERS } from './types';

export const fetchDers = (userId) => dispatch => {
    dispatch({ type: DERS.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Ders/Getir`, {
            method: 'POST',
            body: JSON.stringify(userId),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        }
        )
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: DERS.FETCH_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: DERS.FETCH_SUCCESS, 
                    ders: json
                });
            }
        })
        .catch(error => dispatch({
            type: DERS.FETCH_ERROR,
            message: error.message
        }));
};

// kontrol edilecek
/*
export const updateDers = ({
    dersId,
    dersAdi,
    sinavSoruSayisi,
    sinavSuresi
}) => dispatch => {

    dispatch({ type: DERS.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/Ders/Yaz`,
        {
            method: 'POST',
            body: JSON.stringify({
                dersId,
                dersAdi,
                sinavSoruSayisi,
                sinavSuresi
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: DERS.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: DERS.UPDATE_SUCCESS,
                    ders: json
                });
            }
        })
        .catch(error => dispatch({
            type: DERS.UPDATE_ERROR,
            message: error.message
        }));
};
*/