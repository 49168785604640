import { DERS } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_DERS = {
    dersId: '', // PRIMARY KEY
    dersAdi: '',
    dersKisaAdi: '',
    soruSayisi: {
        deneme: 0,
        ogren: 0,
        uygula: 0,
        basar: 0
    },
    verilecekSure: {
        deneme: 0,
        ogren: 0,
        uygula: 0,
        basar: 0
    }
}

const ders = (state = { dersler: [DEFAULT_DERS] }, action) => {
    switch(action.type) {
        case DERS.FETCH:
            return { ...state, status: fetchStates.fetching };
        case DERS.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case DERS.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, ...action.ders };
        default:
            return state;
    }
}

export default ders;