import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { fetchAuthenticated } from './actions/account';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

const composeEnhancer = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
);

store.dispatch(fetchAuthenticated({
  id: "m",
  healthiergeSerialNumber: localStorage.getItem("serialNumber"),
  healthiergeDateOfFirstVisit: localStorage.getItem("dateOfFirstVisit"),
  healthiergeAccountId: localStorage.getItem("accountId"),
  language: window.navigator.language,
  platform: window.navigator.platform,
  vendor: window.navigator.vendor,
  appName: window.navigator.appName,
  userAgent: window.navigator.userAgent,
  product: window.navigator.product,
  productSub: window.navigator.productSub,
  cookie: window.navigator.cookieEnabled
}))
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root')
    );
});