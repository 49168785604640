import React, { Component } from 'react';
import { connect } from 'react-redux';
import spinnerImg from './assets/derzane-arma-mavi-200.png';

import {
    FormControl,
    Button,
    InputGroup } from 'react-bootstrap';

import {
    insertDersSoru,
    updateDersSoru,
    deleteDersSoru } from './actions/dersSoru';
import { fetchKonu } from './actions/konu';
import { fetchAltKonu } from './actions/altkonu';
import fetchStates from './reducers/fetchStates';
import SoruAvatar from './SoruAvatar';

import SunEditor, { plugins } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import katex from 'katex';
import 'katex/dist/katex.min.css';

const sunEditorOptions = {
    katex: {
        src: katex,
        options: {
            //* default options **
            // throwOnError: false,
        }
    },
    plugins: plugins,
    buttonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        '/', // Line break
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio', 'math' ],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        ['save', 'template']
    ]
};

class SoruEditor extends Component {
    state = {
        soruId: 0,
        metin: '',            // MEDIUMBLOB
        secenek1: '',         // MEDIUMBLOB
        secenek2: '',         // MEDIUMBLOB
        secenek3: '',         // MEDIUMBLOB
        secenek4: '',         // MEDIUMBLOB
        ipucu: '',            // MEDIUMBLOB
        cozum: '',            // MEDIUMBLOB
        dogruCevap: '',
        etiket: '',
        konuId: 0,
        altKonuId: 0,
        kategoriId: 'K',
        kaynak: '',
        validMessage: '',
        soruChanged: false,
        soruAvatarAcik: false
    }

    componentDidMount() {
        const id = this.props.account.id;
        let konuId;
        this.props.fetchKonu(id, this.props.sinifId, this.props.dersId);

        if (this.props.soru !== undefined) {
            const soru = this.props.soru;
            konuId = soru.konuId;
            this.setState({
                soruId: soru.soruId,
                dogruCevap: soru.dogruCevap,
                etiket: soru.etiket,
                konuId: soru.konuId,
                altKonuId: soru.altKonuId,
                kategoriId: soru.kategoriId,
                kaynak: soru.kaynak
            });
        } else {
            konuId = (this.props.konuId !== 'H' ? this.props.konuId : 1);
            this.setState({
                soruId: 0,
                konuId,
                altKonuId: (this.props.altKonuId !== 'H' ? this.props.altKonuId : 1),
                kategoriId: (this.props.kategoriId !== 'H' ? this.props.kategoriId : 'K'),
                etiket: this.props.etiket,
                kaynak: this.props.kaynak
            });
        }
        this.props.fetchAltKonu(id, this.props.sinifId, this.props.dersId, konuId);
    }

    checkValidity = () => {
        if (this.state.metin === '') {
            this.setState({ validMessage: 'Lütfen soru metnini yazınız' });
            return false;
        }

        if (this.state.secenek1 === '') {
            this.setState({ validMessage: 'Lütfen A seçeneğini yazınız' });
            return false;
        }

        if (this.state.secenek2 === '') {
            this.setState({ validMessage: 'Lütfen B seçeneğini yazınız' });
            return false;
        }

        if (this.state.secenek3 === '') {
            this.setState({ validMessage: 'Lütfen C seçeneğini yazınız' });
            return false;
        }

        if (this.state.secenek4 === '') {
            this.setState({ validMessage: 'Lütfen D seçeneğini yazınız' });
            return false;
        }

        /*
        if (this.state.ipucu === '') {
            this.setState({ validMessage: 'Lütfen ipucunu yazınız' });
            return false;
        }

        if (this.state.cozum === '') {
            this.setState({ validMessage: 'Lütfen çözümü yazınız' });
            return false;
        }
        */

        if (this.state.dogruCevap === '') {
            this.setState({ validMessage: 'Lütfen doğru cevabı işaretleyiniz' });
            return false;
        }

        /*
        if (this.state.etiket === '') {
            this.setState({ validMessage: 'Lütfen soru etiketini yazınız' });
            return false;
        }
        */

        if (this.state.kaynak === '') {
            this.setState({ validMessage: 'Lütfen sorunun kaynağını yazınız' });
            return false;
        }

        this.setState({ validMessage: '' });
        return true;
    }

    onChange = (contents, tagName) => {
        const id = this.props.account.id;
        switch (tagName) {
            case 'konuId':
                this.setState({
                    [tagName]: contents.target.value,
                    altKonuId: 1 });
                this.props.fetchAltKonu(
                    id,
                    this.props.sinifId,
                    this.props.dersId,
                    contents.target.value);
                break;
            case 'etiket':
            case 'kategoriId':
            case 'kaynak': 
            case 'altKonuId':
                this.setState({ [tagName]: contents.target.value });
                break;
            case 'metin':
            case 'secenek1':
            case 'secenek2':
            case 'secenek3':
            case 'secenek4':
            case 'ipucu':
            case 'cozum':
            default:
                if (contents === '<p><br></p>')
                    contents = '';
                this.setState({ [tagName]: contents });
                break;
        }
        this.setState({ soruChanged: true }); 
    }

    onDogruCevap = (event) => {
        this.setState({ dogruCevap: event.target.value, soruChanged: true });
    }

    toggleAvatar = () => {
        this.setState({ soruAvatarAcik: !this.state.soruAvatarAcik });
    }

    yoket = () => {
        this.setState({ validMessage: '' });
        let soru = {};
        soru.soruId = this.state.soruId;
        soru.id = this.props.account.id;
        soru.sinifId = this.props.sinifId;
        soru.dersId = this.props.dersId;

        let soruData = new FormData();
        soruData.set('soru', JSON.stringify(soru));

        this.props.deleteDersSoru(soruData);
        if( this.props.geriDon)
            this.props.geriDon();
    }

    duzelt = () => {
        if (!this.checkValidity())
            return;

        let soru = {};
        soru.soruId = this.state.soruId;
        soru.dogruCevap= this.state.dogruCevap;
        soru.etiket = this.state.etiket;
        soru.konuId= this.state.konuId;
        soru.altKonuId = this.state.altKonuId;
        soru.kategoriId = this.state.kategoriId;
        soru.kaynak = this.state.kaynak;
        soru.id = this.props.account.id;
        soru.sinifId = this.props.sinifId;
        soru.dersId = this.props.dersId;

        let soruData = new FormData();
        soruData.set('soru', JSON.stringify(soru));
        soruData.set('metin', this.state.metin);
        soruData.set('secenek1', this.state.secenek1);
        soruData.set('secenek2', this.state.secenek2);
        soruData.set('secenek3', this.state.secenek3);
        soruData.set('secenek4', this.state.secenek4);
        soruData.set('ipucu', this.state.ipucu);
        soruData.set('cozum', this.state.cozum);

        this.props.updateDersSoru(soruData);

        if( this.props.geriDon)
            this.props.geriDon();
    }

    ekle = () => {
        if (!this.checkValidity())
            return;

        let soru = {};
        soru.soruId = this.state.soruId;
        soru.dogruCevap= this.state.dogruCevap;
        soru.etiket = this.state.etiket;
        soru.konuId= this.state.konuId;
        soru.altKonuId = this.state.altKonuId;
        soru.kategoriId = this.state.kategoriId;
        soru.kaynak = this.state.kaynak;
        soru.id = this.props.account.id;
        soru.sinifId = this.props.sinifId;
        soru.dersId = this.props.dersId;

        let soruData = new FormData();
        soruData.set('soru', JSON.stringify(soru));
        soruData.set('metin', this.state.metin);
        soruData.set('secenek1', this.state.secenek1);
        soruData.set('secenek2', this.state.secenek2);
        soruData.set('secenek3', this.state.secenek3);
        soruData.set('secenek4', this.state.secenek4);
        soruData.set('ipucu', this.state.ipucu);
        soruData.set('cozum', this.state.cozum);

        this.props.insertDersSoru(soruData);
        if( this.props.geriDon)
            this.props.geriDon();
    }

    geriDon = () => {
        this.setState({ validMessage: '' });

        if (this.state.soruChanged) {

        }

        if (this.props.geriDon)
            this.props.geriDon();
    }

    render() {
        const dersSoru = this.props.dersSoru;
        if (dersSoru.status === fetchStates.fetching ||
            dersSoru.soruDetay.status === fetchStates.fetching) {
            return (
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Soru yükleniyor
                </div>
            )
        }
        const soruDetay = dersSoru.soruDetay[0];
        const secenekler = [];

        secenekler.push(soruDetay.secenek1);
        secenekler.push(soruDetay.secenek2);
        secenekler.push(soruDetay.secenek3);
        secenekler.push(soruDetay.secenek4);

        const konular = this.props.konu.konular;
        const altkonular = this.props.altkonu.altkonular;

        let soru = {};
        soru.soruId = this.state.soruId;
        soru.dogruCevap= this.state.dogruCevap;
        soru.etiket = this.state.etiket;
        soru.konuId= this.state.konuId;
        soru.altKonuId = this.state.altKonuId;
        soru.kategoriId = this.state.kategoriId;
        soru.kaynak = this.state.kaynak;
        soru.metin = this.state.metin || (this.state.soruId === 0 ? '' : soruDetay.metin);
        soru.secenek1 = this.state.secenek1 || (this.state.soruId === 0 ? '' : soruDetay.secenek1);
        soru.secenek2 = this.state.secenek2 || (this.state.soruId === 0 ? '' : soruDetay.secenek2);
        soru.secenek3 = this.state.secenek3 || (this.state.soruId === 0 ? '' : soruDetay.secenek3);
        soru.secenek4 = this.state.secenek4 || (this.state.soruId === 0 ? '' : soruDetay.secenek4);

        soru.ipucu = this.state.ipucu || (this.state.soruId === 0 ? '' : soruDetay.ipucu);
        soru.cozum = this.state.cozum || (this.state.soruId === 0 ? '' : soruDetay.cozum);

        return (
            <div>
            {
                this.state.validMessage !== '' ?
                <div className="Warning w_top">
                    {this.state.validMessage}
                </div> : null
            }
            <div className="Page">
            {
            this.state.soruAvatarAcik ? 
            <SoruAvatar
                soru={soru}
                dersId={this.props.dersId}
                dersAdi={this.props.dersAdi}
                sinifId={this.props.sinifId}
                geriDon={this.toggleAvatar}
            />
            :
            <div>
                <div className="PageHeader">
                    {this.state.soruId > 0 ?
                        `Soru ${this.state.soruId}` : 'Yeni Soru'}
                </div>
                <div className='PageTextCenter'>
                <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text
                            style={{ width: "100px"}}>Ünite</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.konuId}
                            onChange={c => this.onChange(c, 'konuId')}
                            placeholder='konuId'
                            name='konuId'
                        >
                            {
                            konular.map((konu,i) => (
                                <option key={i} value={konu.konuId}>
                                    {konu.konuId}. {konu.konuAdi}
                                </option>
                            ))
                            }
                        </FormControl>  
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text
                            style={{ width: "100px"}}>Konu</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.altKonuId}
                            onChange={c => this.onChange(c, 'altKonuId')}
                            placeholder='altKonuId'
                            name='altKonuId'
                        >
                            {
                            altkonular.map((altkonu,i) => (
                                <option key={i} value={altkonu.altKonuId}>
                                    {altkonu.altKonuId}. {altkonu.altKonuAdi}
                                </option>
                            ))
                            }
                        </FormControl>  
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text
                            style={{ width: "100px"}}>Kategori</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.kategoriId}
                            onChange={c => this.onChange(c, 'kategoriId')}
                            placeholder='kategoriId'
                            name='kategoriId'
                        >
                            <option value='K'>Kavrama</option>
                            <option value='U'>Uygulama</option>
                            <option value='A'>Analiz</option>
                        </FormControl>  
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ "width" : '100px' }}>Etiket</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="etiket"
                            value={this.state.etiket}
                            onChange={c => this.onChange(c, 'etiket')}
                            placeholder='Soru için etiket tanımlayınız'
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ "width" : '100px' }}>Kaynak</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="kaynak"
                            value={this.state.kaynak}
                            onChange={c => this.onChange(c, 'kaynak')}
                            placeholder='Soru için kaynak tanımlayınız'
                        />
                    </InputGroup>
                </div>
                <hr />
                <div className='PageText'>
                    <p>
                        Soru metnini başka bir dosyadan kopyaladığınız durumda;
                    </p>
                    <p>
                        1- Metnin tamamını işaretleyip 'Remove Format' tuşu ile taşınmış olan formatı yok ediniz
                    </p>
                    <p>
                        2- Metnin tamamını işaretleyip 'Align justify' tuşuna basınız
                    </p>
                    <p>
                        3- Eğer mevcutsa, metnin içerisinde geçen kelime ayraçlarını ('-') siliniz 
                    </p>
                    <p>
                        4- Metnin ilgili yerlerini 'Bold' ve/veya 'Underline' seçeneklerini kullanarak işaretleyiniz
                    </p>
                </div>
                <hr />
                <div className='PageTextCenter'>
                    <div>Soru Metni</div>
                    
                    <SunEditor
                        setOptions={sunEditorOptions}
                        onChange={c => this.onChange(c, 'metin')}
                        setContents={this.state.metin || 
                                    (this.state.soruId === 0 ? '' : soruDetay.metin)}
                    />
                </div>
                <div className="PageTextCenter">
                    {
                    secenekler.map((secenek,index) => {
                        return (
                            <div key={index}>
                                <Button
                                    variant={String.fromCharCode(65+index) ===
                                            this.state.dogruCevap ?
                                        "info" : "outline-info"}
                                    value={String.fromCharCode(65+index)}
                                    onClick={this.onDogruCevap}
                                > {String.fromCharCode(65+index)}) </Button>
                                <SunEditor
                                    setOptions={sunEditorOptions}
                                    onChange={c => this.onChange(c, `secenek${index+1}`)}
                                    setContents={this.state[`secenek${index+1}`] ||
                                                (this.state.soruId === 0 ? '' : 
                                                soruDetay[`secenek${index+1}`])}
                                />
                            </div>
                        );
                    })
                    }
                </div>
                <hr />
                <div className='PageTextCenter'>
                    <div>İpucu</div>
                    <SunEditor
                        setOptions={sunEditorOptions}
                        onChange={c => this.onChange(c, 'ipucu')}
                        setContents={this.state.ipucu || 
                                    (this.state.soruId === 0 ? '' : soruDetay.ipucu)}
                    />
                </div>
                <hr />
                <div className="PageTextCenter">
                    <div>Çözüm</div>
                    <SunEditor
                        setOptions={sunEditorOptions}
                        onChange={c => this.onChange(c, 'cozum')}
                        setContents={this.state.cozum || 
                            (this.state.soruId === 0 ? '' : soruDetay.cozum)}
                    />
                </div>
                <hr />
                <div className='PageTextCenter'>
                    <Button variant="success" onClick={this.ekle}>{this.state.soruId === 0 ? 'Kaydet' : 'Kopya Oluştur'}</Button>
                    {' '}
                    {
                        this.state.soruId === 0 ? null :
                        <Button variant="warning" onClick={this.duzelt}>Düzelt</Button>
                    }
                    {' '}
                    {
                        this.state.soruId === 0 ? null :
                        <Button variant="danger" onClick={this.yoket}>Sil</Button>
                    }
                    {' '}
                    <Button variant="secondary" onClick={this.geriDon}>Geri Dön</Button>
                    {' '}
                    <Button variant="outline-info" onClick={this.toggleAvatar}>Göster</Button>
                </div>
            </div>
            }
            </div>
            {
                this.state.validMessage !== '' ?
                <div className="Warning w_bottom">
                    {this.state.validMessage}
                </div> : null
            }
            </div>
        );
    }
}

export default connect(
    ({
        account,
        dersSoru,
        konu,
        altkonu
    }) => ({
        account,
        dersSoru,
        konu,
        altkonu
    }),
    {
        insertDersSoru,
        updateDersSoru,
        deleteDersSoru,
        fetchKonu,
        fetchAltKonu
    }
)(SoruEditor);