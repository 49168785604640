import { BACKEND } from '../secrets/config';
import { ALTKONU } from './types';

export const fetchAltKonu = (userId, sinifId, dersId, konuId) => dispatch => {
    dispatch({ type: ALTKONU.FETCH });

    return fetch(`${BACKEND.ADDRESS}/AltKonu/Getir`, {
            method: 'POST',
            body: JSON.stringify({ userId, sinifId, dersId, konuId }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ALTKONU.FETCH_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ALTKONU.FETCH_SUCCESS, 
                    altkonular: json
                });
            }
        })
        .catch(error => dispatch({
            type: ALTKONU.FETCH_ERROR,
            message: error.message
        }));
};

// kontrol edilecek
export const updateAltKonu = ({
    sinifId,
    dersId,
    konuId,
    altKonuId,
    altKonuAdi
}) => dispatch => {

    dispatch({ type: ALTKONU.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/AltKonu/Yaz`,
        {
            method: 'POST',
            body: JSON.stringify({
                sinifId,
                dersId,
                konuId,
                altKonuId,
                altKonuAdi
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ALTKONU.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ALTKONU.UPDATE_SUCCESS,
                    altkonu: json
                });
            }
        })
        .catch(error => dispatch({
            type: ALTKONU.UPDATE_ERROR,
            message: error.message
        }));
};