import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './Menu';
import Footer from './Footer';
import Tail from './Tail';

import AuthForm from './AuthForm';
import AnaSayfa from './AnaSayfa';
import DersSorulari from './DersSorulari';
import SSS from './SSS';
import Iletisim from './Iletisim';
import GizlilikPolitikasi from './GizlilikPolitikasi';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Menu />
        {
          !this.props.account.loggedIn ? <AuthForm /> :
          <Switch>
            <Route path='/sik-sorulan-sorular' component={SSS} />
            <Route path='/iletisim' component={Iletisim} />
            <Route path='/gizlilik-politikasi' component={GizlilikPolitikasi} />

            {this.props.account.accountStatus === 'AD' ? <Route path='/deneme-sinavi' component={Iletisim} />:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/mat'><DersSorulari dersId='Mat'/></Route>:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/fen'><DersSorulari dersId='Fen'/></Route>:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/trk'><DersSorulari dersId='Trk'/></Route>:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/ink'><DersSorulari dersId='Ink'/></Route>:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/din'><DersSorulari dersId='Din'/></Route>:null}
            {this.props.account.accountStatus === 'AD' ? <Route path='/yen'><DersSorulari dersId='Yen'/></Route>:null}
            <Route path='/' component={AnaSayfa}/>
          </Switch>
        }
        <Tail />
        <Footer />
      </BrowserRouter>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  null
)(App);