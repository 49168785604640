import React, { Component } from 'react';
import { connect } from 'react-redux';
import spinnerImg from './assets/derzane-arma-mavi-200.png';

import { fetchKonu } from './actions/konu';
import { fetchAltKonu } from './actions/altkonu';
import fetchStates from './reducers/fetchStates';
import SoruAvatar from './SoruAvatar';

class SoruGoster extends Component {
    state = {
        soruId: 0,
        metin: '',            // MEDIUMBLOB
        secenek1: '',         // MEDIUMBLOB
        secenek2: '',         // MEDIUMBLOB
        secenek3: '',         // MEDIUMBLOB
        secenek4: '',         // MEDIUMBLOB
        ipucu: '',            // MEDIUMBLOB
        cozum: '',            // MEDIUMBLOB
        dogruCevap: '',
        etiket: '',
        konuId: 0,
        altKonuId: 0,
        kategoriId: 'K',
        kaynak: '',
        validMessage: ''
    }

    componentDidMount() {
        const id = this.props.account.id;
        let konuId;
        this.props.fetchKonu(id, this.props.sinifId, this.props.dersId);

        if (this.props.soru !== undefined) {
            const soru = this.props.soru;
            konuId = soru.konuId;
            this.setState({
                soruId: soru.soruId,
                dogruCevap: soru.dogruCevap,
                etiket: soru.etiket,
                konuId: soru.konuId,
                altKonuId: soru.altKonuId,
                kategoriId: soru.kategoriId,
                kaynak: soru.kaynak
            });
        } else {
            konuId = (this.props.konuId !== 'H' ? this.props.konuId : 1);
            this.setState({
                soruId: 0,
                konuId,
                altKonuId: (this.props.altKonuId !== 'H' ? this.props.altKonuId : 1),
                kategoriId: (this.props.kategoriId !== 'H' ? this.props.kategoriId : 'K'),
                etiket: this.props.etiket,
                kaynak: this.props.kaynak
            });
        }
        this.props.fetchAltKonu(id, this.props.sinifId, this.props.dersId, konuId);
    }

    geriDon = () => {
        this.setState({ validMessage: '' });

        if (this.props.geriDon)
            this.props.geriDon();
    }

    render() {
        const dersSoru = this.props.dersSoru;
        if (dersSoru.status === fetchStates.fetching ||
            dersSoru.soruDetay.status === fetchStates.fetching) {
            return (
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Soru yükleniyor
                </div>
            )
        }
        const soruDetay = dersSoru.soruDetay[0];
        const secenekler = [];

        secenekler.push(soruDetay.secenek1);
        secenekler.push(soruDetay.secenek2);
        secenekler.push(soruDetay.secenek3);
        secenekler.push(soruDetay.secenek4);

//        const konular = this.props.konu.konular;
//        const altkonular = this.props.altkonu.altkonular;

        let soru = {};
        soru.soruId = this.state.soruId;
        soru.dogruCevap= this.state.dogruCevap;
        soru.etiket = this.state.etiket;
        soru.konuId= this.state.konuId;
        soru.altKonuId = this.state.altKonuId;
        soru.kategoriId = this.state.kategoriId;
        soru.kaynak = this.state.kaynak;
        soru.metin = this.state.metin || (this.state.soruId === 0 ? '' : soruDetay.metin);
        soru.secenek1 = this.state.secenek1 || (this.state.soruId === 0 ? '' : soruDetay.secenek1);
        soru.secenek2 = this.state.secenek2 || (this.state.soruId === 0 ? '' : soruDetay.secenek2);
        soru.secenek3 = this.state.secenek3 || (this.state.soruId === 0 ? '' : soruDetay.secenek3);
        soru.secenek4 = this.state.secenek4 || (this.state.soruId === 0 ? '' : soruDetay.secenek4);

        soru.ipucu = this.state.ipucu || (this.state.soruId === 0 ? '' : soruDetay.ipucu);
        soru.cozum = this.state.cozum || (this.state.soruId === 0 ? '' : soruDetay.cozum);

        return (
            <div>
            {
                this.state.validMessage !== '' ?
                <div className="Warning w_top">
                    {this.state.validMessage}
                </div> : null
            }
            <div className="Page">
                <SoruAvatar
                    soru={soru}
                    dersId={this.props.dersId}
                    dersAdi={this.props.dersAdi}
                    sinifId={this.props.sinifId}
                    geriDon={this.geriDon}
                />
            </div>
            {
                this.state.validMessage !== '' ?
                <div className="Warning w_bottom">
                    {this.state.validMessage}
                </div> : null
            }
            </div>
        );
    }
}

export default connect(
    ({
        account,
        dersSoru,
        konu,
        altkonu
    }) => ({
        account,
        dersSoru,
        konu,
        altkonu
    }),
    {
        fetchKonu,
        fetchAltKonu
    }
)(SoruGoster);