import { STATS } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_STATS = {

}

const stats = (state = { dersler: [DEFAULT_STATS] }, action) => {
    switch(action.type) {
        case STATS.FETCH:
            return { ...state, status: fetchStates.fetching };
        case STATS.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case STATS.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, ...action };
        default:
            return state;
    }
}

export default stats;