import { AYARLAR } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_AYARLAR = { 
    konuId: '',
    kategoriId: '',
    dogruCevapGoster: '',
    soruFiltreSekli: '',
    zamanTut: ''
}

const ayarlar = (state = DEFAULT_AYARLAR, action) => {
    switch(action.type) {
        case AYARLAR.FETCH:
            return { ...state, status: fetchStates.fetching };
        case AYARLAR.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case AYARLAR.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, ...action.ayarlar };
        case AYARLAR.UPDATE:
            return { ...state, status: fetchStates.fetching };
        case AYARLAR.UPDATE_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case AYARLAR.UPDATE_SUCCESS:
            return { ...state, status: fetchStates.success, ...action.ayarlar };
        default:
            return state;
    }
}

export default ayarlar;