import React, { Component } from 'react';
import { connect } from 'react-redux';
import spinnerImg from './assets/derzane-arma-mavi-200.png';
import fetchStates from './reducers/fetchStates';
import { fetchDersSoruListele } from './actions/dersSoru';
import Button from 'react-bootstrap/Button';

class DersListele extends Component {
    handleClick = (soru) => {
        if (this.props.soruEditor)
            this.props.soruEditor(soru);
    }

    showSoru = (soru) => {
        if (this.props.soruGoster)
            this.props.soruGoster(soru);
    }

    render() {
        const dersSoru = this.props.dersSoru;
        if (dersSoru.status === fetchStates.fetching) {
            return (
                <div className="Warning w_bottom">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Sorular yükleniyor 
                </div>
            )
        }

        let sorular = dersSoru.sorular;
        if (sorular.length === 0) {
            return (
                <div className="Warning w_bottom">
                    Seçtiğiniz grupta soru bulunamadı
                </div>
            )
        }

        return (
            <div className='PageEnd'>
                <hr />
                <div className='PageTable'>
                    <div className='PageTableRowHeader'>
                        <div className='PageTableCell'>
                            Sıra
                        </div>
                        <div className='PageTableCell'>
                            Konu Kodu
                        </div>
                        <div className='PageTableCell'>
                            Kategori
                        </div>
                        <div className='PageTableCell'>
                            Soru No
                        </div>
                        <div className='PageTableCell'>
                            Etiket
                        </div>
                        <div className='PageTableCell'>
                            Kaynak
                        </div>
                        <div className='PageTableCell'>
                            Cevap
                        </div>
                    </div>
                {
                sorular.map((soru,i) =>
                    <div className='PageTableRow' key={i}>
                        <div className='PageTableCell'>
                            {i+1}
                        </div>
                        <div className='PageTableCell'>
                            {this.props.dersId}.{this.props.sinifId}.{soru.konuId}.{soru.altKonuId}
                        </div>
                        <div className='PageTableCell'>
                            {soru.kategoriId === 'K' ? 'Kavrama' :
                             soru.kategoriId === 'U' ? 'Uygulama' : 'Analiz' }
                        </div>
                        <div className='PageTableCell'>
                            {soru.soruId}
                        </div>
                        <div className='PageTableCell'>
                            {soru.etiket}
                        </div>
                        <div className='PageTableCell'>
                            {soru.kaynak}
                        </div>
                        <div className='PageTableCell'>
                            {soru.dogruCevap}
                        </div>
                        <div className='PageTableCell'>
                            <Button variant="outline-info" size="sm" onClick={() => this.showSoru(soru)}>Göster</Button>
                        </div>
                        <div className='PageTableCell'>
                            <Button variant="warning" size="sm" onClick={() => this.handleClick(soru)}>Düzelt</Button>
                        </div>
                    </div>
                )} 
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account, konu, dersSoru }) => ({ account, konu, dersSoru }),
    { fetchDersSoruListele }
)(DersListele);