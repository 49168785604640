import { BACKEND } from '../secrets/config';
import { DERSSORU, CEVAP } from './types';

export const zamanDersSoru = (zaman) =>  dispatch => {
    dispatch({ type: DERSSORU.ZAMAN, zaman });
}

export const insertDersSoru = (soruData) => dispatch => {
    dispatch({ type: DERSSORU.INSERT });

    let options = {
        method: 'POST',
        body: soruData,
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/Ekle`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.INSERT_ERROR, message: json.message });
            } else {
                dispatch({ type: DERSSORU.INSERT_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.INSERT, message: error.message }));
};

export const updateDersSoru = (soruData) => dispatch => {
    dispatch({ type: DERSSORU.UPDATE });

    let options = {
        method: 'POST',
        body: soruData,
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/Duzelt`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.UPDATE_ERROR, message: json.message });
            } else {
                dispatch({ type: DERSSORU.UPDATE_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.UPDATE, message: error.message }));
};

export const deleteDersSoru = (soruData) => dispatch => {
    dispatch({ type: DERSSORU.DELETE });

    let options = {
        method: 'POST',
        body: soruData,
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/Sil`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.DELETE_ERROR, message: json.message });
            } else {
                dispatch({ type: DERSSORU.DELETE_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.DELETE, message: error.message }));
};

export const fetchDersSoru = (soru) => dispatch => {
    dispatch({ type: DERSSORU.FETCH });

    const options = {
        method: 'POST',
        body: JSON.stringify(soru),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/Getir`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.FETCH_ERROR, message: json.message });
            } else {
                let correctRowNumbers = json[0].soruId === 0 ? 0 : json.length;
                dispatch({ type: DERSSORU.FETCH_SUCCESS, sorular: json, soruSayisi: `${correctRowNumbers}` });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.FETCH_ERROR, message: error.message }));
};

export const fetchDersSoruListele = (soru) => dispatch => {
    dispatch({ type: DERSSORU.FETCH });

    const options = {
        method: 'POST',
        body: JSON.stringify(soru),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/Listele`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.FETCH_ERROR, message: json.message });
            } else {
                let correctRowNumbers = json.length;
                dispatch({ type: DERSSORU.FETCH_SUCCESS, sorular: json, soruSayisi: `${correctRowNumbers}` });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.FETCH_ERROR, message: error.message }));
};

export const fetchDersSoruFromSoruId = ({ id, sinifId, dersId, soruId }) => dispatch => {
    dispatch({ type: DERSSORU.FETCH });

    const options = {
        method: 'POST',
        body: JSON.stringify({
            id,
            sinifId,
            dersId,
            soruId
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/DersSoru/SoruIdIleGetir`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: DERSSORU.FETCH_ERROR, message: json.message });
            } else {
                dispatch({ type: DERSSORU.FETCH_DETAILS, soruDetay: json });
            }
        })
        .catch(error => dispatch({ type: DERSSORU.FETCH_ERROR, message: error.message }));
};

export const cevapInsert = (cevap) => dispatch => {
    dispatch({ type: CEVAP.INSERT });

    let options = {
        method: 'POST',
        body: JSON.stringify(cevap),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Cevap/Ekle`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: CEVAP.INSERT_ERROR, message: json.message });
            } else {
                dispatch({ type: CEVAP.INSERT_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: CEVAP.INSERT_ERROR, message: error.message }));
};