import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Iletisim extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Iletisim: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='İletişim'/>
                <div className="Page">
                    <div className="PageSection">
                        WhatsApp
                    </div>
                    <div className="PageText">
                        ......
                    </div>
                    <div className="PageSection">
                        E-Mail
                    </div>
                    <div className="PageText">
                        iletisim@derzane.com
                    </div>
                    <div className="PageSection">
                        Facebook
                    </div>
                    <div className="PageText">
                    https://facebook/derzane.medya
                    </div>
                    <div className="PageSection">
                        Instagram
                    </div>
                    <div className="PageText">
                    https://instagram/derzane.medya
                    </div>
                    <div className="PageSection">
                        Twitter
                    </div>
                    <div className="PageText">
                    https://twitter/derzane.medya
                    </div>
                    <div className="PageSection">
                        Adres
                    </div>
                    <div className="PageText">
                        <div>ABC Mah</div>
                        <div>BDC Sok. 10 No</div>   
                        <div>Nişantaşı Istanbul</div>
                    </div>
                    <div className="PageSection">
                        Telefon
                    </div>
                    <div className="PageText">
                        .....
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Iletisim);