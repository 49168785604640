import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from './actions/account';

import derZaneLogo from './assets/derzane-logo-2000.png';

class Menu extends Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        smallMenuLimit: 768
    }

    getMenuSize = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('resize', this.getMenuSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getMenuSize);
    }

    logout = () => {
        const id = this.props.account.id;
        this.props.logout(id);
    }

    render() {
        return (
            <div className="Menu">
                <div className="MenuLogo">
                    <Link to='/'>
                        <img src={derZaneLogo} width="100%" alt='derZane.com' />
                    </Link>
                </div>     
                {
                this.props.account.loggedIn ?       
                <div className="MenuContainer">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div className="MenuItem" onClick={this.logout}>Çıkış</div>
                </div> : null
                }
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { logout }
)(Menu);