import { DERSSORU } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_DERSSORU = {
    soruId: 0,
    metin: '',
    secenek1: '',
    secenek2: '',
    secenek3: '',
    secenek4: '',
    cozum: '',
    dogruCevap: '',
    verilenCevap: '',
    etiket: '',
    konuId: 0,
    altKonuId: 0,
    kategoriId: '',
    kaynak: '',
    gecenSure: 0
}

const dersSoru = (state = {
        soruSayisi: 0,
        zaman: 0,
        sorular: [DEFAULT_DERSSORU],
        soruDetay: [DEFAULT_DERSSORU],
    }, action) => {
    switch(action.type) {
        case DERSSORU.FETCH:
            return { ...state, status: fetchStates.fetching };
        case DERSSORU.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case DERSSORU.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, ...action };
        case DERSSORU.FETCH_DETAILS:
            return { ...state, status: fetchStates.success, ...action };
        case DERSSORU.ZAMAN:
            return {...state, ...action };
        default:
            return state;
    }
}

export default dersSoru;