import { BACKEND } from '../secrets/config';
import { KONU } from './types';

export const fetchKonu = (id, sinifId, dersId) => dispatch => {
    dispatch({ type: KONU.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Konu/Getir`, {
            method: 'POST',
            body: JSON.stringify({ id, sinifId, dersId }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        }
        )
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: KONU.FETCH_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: KONU.FETCH_SUCCESS, 
                    konular: json
                });
            }
        })
        .catch(error => dispatch({
            type: KONU.FETCH_ERROR,
            message: error.message
        }));
};

// kontrol edilecek
export const updateKonu = ({
    sinifId,
    dersId,
    konuId,
    konuAdi
}) => dispatch => {

    dispatch({ type: KONU.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/Konu/Yaz`,
        {
            method: 'POST',
            body: JSON.stringify({
                sinifId,
                dersId,
                konuId,
                konuAdi
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: KONU.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: KONU.UPDATE_SUCCESS,
                    konu: json
                });
            }
        })
        .catch(error => dispatch({
            type: KONU.UPDATE_ERROR,
            message: error.message
        }));
};