import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import spinnerImg from './assets/derzane-arma-mavi-200.png';

import { fetchAltKonu } from './actions/altkonu';
import fetchStates from './reducers/fetchStates';

class DersKonulari extends Component {
    state = {
        konuId: this.props.konuId,
        altKonuId: this.props.altKonuId,
        kategoriId: this.props.kategoriId,
        etiket: this.props.etiket,
        kaynak: this.props.kaynak
    }

    componentDidMount() {
        let id = this.props.account.id;
        let { 
            sinifId,
            dersId, 
            konuId,
            altKonuId,
            kategoriId,
            etiket,
            kaynak
        } = this.props;
        this.setState({ konuId, altKonuId, kategoriId, etiket, kaynak });
        this.props.fetchAltKonu(id, sinifId, dersId, konuId);
    }

    handleKategoriIdChange = e => {
        let key = e;
        this.setState({ kategoriId: key });
        if (this.props.konuSecildi)
            this.props.konuSecildi(this.state.konuId, this.state.altKonuId, key, this.state.etiket, this.state.kaynak);
    }

    handleChange = event => {
        let tag = event.target.name;
        let key = event.target.value;

        this.setState({ [tag]: key });
        switch (tag) {
            case 'konuId':
                let id = this.props.account.id;
                let { 
                    sinifId,
                    dersId
                } = this.props;
                this.props.fetchAltKonu(id, sinifId, dersId, key);
                this.setState({ altKonuId: 'H' });
                this.props.konuSecildi(key, 'H', this.state.kategoriId, this.state.etiket, this.state.kaynak);
                break;
            case 'etiket':
                this.props.konuSecildi(this.state.konuId, this.state.altKonuId, this.state.kategoriId, key, this.state.kaynak);
                break;
            case 'kaynak':
                this.props.konuSecildi(this.state.konuId, this.state.altKonuId, this.state.kategoriId, this.state.etiket, key);
                break;
            case 'altKonuId':
            default:
                this.props.konuSecildi(this.state.konuId, key, this.state.kategoriId, this.state.etiket, this.state.kaynak);
                break;
        }
    }

    konuSecildi = () => {
        let {
            konuId,
            altKonuId,
            kategoriId,
            etiket,
            kaynak
        } = this.state;

        this.props.konuSecildi(konuId, altKonuId, kategoriId, etiket, kaynak);
    }

    yeniSoru = () => {
        if (this.props.yeniSoru) {
            let {
                konuId,
                altKonuId,
                kategoriId,
                etiket,
                kaynak
            } = this.state;

            this.props.yeniSoru(
                konuId,
                altKonuId,
                kategoriId,
                etiket,
                kaynak
            );
        }
    }

    render() {
        if (this.props.altkonu.status === fetchStates.fetching) {
            return (
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Ders konuları yükleniyor 
                </div>
            )
        }

        let konular = this.props.konu.konular;
        let altKonular = this.props.altkonu.altkonular;

        return (
            <div className='PageStart'>
                <div className="PageTextCenter">
                    <div>
                        Konular
                    </div>
                    <InputGroup>
                        <FormControl
                            as="select"
                            value={this.state.konuId}
                            onChange={this.handleChange}
                            placeholder='konuId'
                            name='konuId'
                        >
                            <option key='-1' value='H'>
                                Hepsi
                            </option>
                            {
                            konular.map((konu,i) => (
                                <option key={i} value={konu.konuId}>
                                    {konu.konuId}. {konu.konuAdi}
                                </option>
                            ))
                            }
                        </FormControl>  
                    </InputGroup>
                    <div>
                        Alt Konular
                    </div>
                    <InputGroup>
                        <FormControl
                            as="select"
                            value={this.state.altKonuId}
                            onChange={this.handleChange}
                            placeholder='altKonuId'
                            name='altKonuId'
                        >
                            <option key='-1' value='H'>
                                Hepsi
                            </option>
                            {
                            altKonular.map((altKonu,i) => (
                                <option key={i} value={altKonu.altKonuId}>
                                    {altKonu.altKonuId}. {altKonu.altKonuAdi}
                                </option>
                            ))
                            }
                        </FormControl>  
                    </InputGroup>
                    <div>
                        Soru Kategorisi
                    </div>
                    <InputGroup>
                        <ToggleButtonGroup
                            type="radio"
                            name="kategoriId"
                            defaultValue={this.state.kategoriId}
                            onChange={this.handleKategoriIdChange}
                        >
                            <ToggleButton
                                variant={'outline-secondary'}
                                value={'H'}
                            >
                                Hepsi
                            </ToggleButton>
                            <ToggleButton
                                variant={'outline-success'}
                                value={'K'}
                            >
                                Kavrama
                            </ToggleButton>
                            <ToggleButton
                                variant={'outline-warning'}
                                value={'U'}
                            >
                                Uygulama
                            </ToggleButton>
                            <ToggleButton
                                variant={'outline-danger'}
                                value={'A'}
                            >
                                Analiz
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </InputGroup>
                    <div>
                        Etiket
                    </div>
                    <InputGroup>
                        <FormControl
                            aria-label="Etiket"
                            name='etiket'
                            value={this.state.etiket}
                            onChange={this.handleChange}
                            placeholder='Etiket'
                        />
                    </InputGroup>
                    <div>
                        Kaynak
                    </div>
                    <InputGroup>
                        <FormControl
                            aria-label="Kaynak"
                            name='kaynak'
                            value={this.state.kaynak}
                            onChange={this.handleChange}
                            placeholder='Kaynak'
                        />
                    </InputGroup>
                </div>
                <div className="PageTextCenter">
                    <Button variant='info' onClick={this.yeniSoru}>Yeni Soru Giriş</Button>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account, konu, ders, altkonu }) => ({ account, konu, ders, altkonu }),
    { fetchAltKonu }
)(DersKonulari);