import { ALTKONU } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_ALTKONU = { 
    sinifId: '',
    dersId: '',
    konuId: '',
    altKonuId: '',
    altKonuAdi: ''
}

const altkonu = (state = { altkonular: [DEFAULT_ALTKONU] }, action) => {
    switch(action.type) {
        case ALTKONU.FETCH:
            return { ...state, status: fetchStates.fetching };
        case ALTKONU.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case ALTKONU.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, altkonular: action.altkonular };
        default:
            return state;
    }
}

export default altkonu;