import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import { fetchDers } from './actions/ders';
import { Redirect } from 'react-router-dom';
import fetchStates from './reducers/fetchStates';
import spinnerImg from './assets/derzane-arma-mavi-200.png';
import HeaderSub from './HeaderSub';
import CoverItem from './CoverItem';

import dersImg from './assets/sss.jpg';

let gRedirect = 'none';

class AnaSayfa extends Component {
    state = {
        redirectUpdated: false
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "AdminAnaSayfa: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        this.props.fetchDers({ id });
        window.scrollTo(0, 0);
    }

    callPage = (redirect) => {
        gRedirect = redirect;
        this.setState({ redirectUpdated: true });
    }

    render() {
        // const gecerliTarih = this.props.account.mat; aşağıdaki kısım açılınca bu da açılacak
        const dersler = this.props.ders.dersler;
        let lRedirect = 'none';

        if (gRedirect === 'none') {
            return (
                <div>
                    <HeaderSub header="Soru Bankası Yöneticisi" />
                    {
                    this.props.ders.status === fetchStates.fetching ?
                    <div className="Warning w_top">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Dersler yükleniyor
                    </div> :
                    this.props.account.accountStatus !== 'AD' ?
                    <div className="Warning w_top">
                        Soru bankası yönetim yetkiniz yoktur, tanımlatmak için destek ekibi ile iletişime geçiniz
                    </div> : null
                    }
                    <div className="Cover">
                        {/*
                        <CoverItem
                            id="Deneme"
                            header="Deneme Sınavı"
                            image={hakkimizdaImg}
                            redirect="/deneme-sinavi"
                            callPage={this.callPage}
                        />
                        */}
                        {
                            dersler.map((ders, index) => {
                                return (<CoverItem
                                    key={index}
                                    id={ders.dersId}
                                    header={ders.dersAdi}
                                    image={dersImg}
                                    redirect={`/${ders.dersId.toLowerCase()}`}
                                    callPage={this.callPage}
                                />)
                            })
                        }
                    </div>
                    {
                    this.props.ders.status === fetchStates.fetching ?
                    <div className="Warning w_bottom">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Dersler yükleniyor
                    </div> : 
                    this.props.account.accountStatus !== 'AD' ?
                    <div className="Warning w_bottom">
                        Soru bankası yönetim yetkiniz yoktur, tanımlatmak için destek ekibi ile iletişime geçiniz
                    </div> :null
                    }
                </div>
            );
        }
        lRedirect = gRedirect;
        gRedirect = 'none';
        return (<Redirect push to={lRedirect} />);
    }
}

export default connect(
    ({ account, ders }) => ({ account, ders }),
    { invokeLogger, fetchDers }
)(AnaSayfa);
