import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import derZaneLogo from './assets/derzane-arma-trnc-200.png';
import facebook from './assets/s_facebook.png';
import instagram from './assets/s_instagram.png';
import discord from './assets/s_discord.png';
import twitter from './assets/s_twitter.png';


class Footer extends Component {
    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'facebook':
                url = 'https://fb.me/derzane.medya';
                break;
            case 'instagram':
                url = 'https://www.instagram.com/derzane.medya/';
                break;
            case 'linkedin':
                url = 'https://www.linkedin.com/company/derzane.medya/';
                break;
            case 'twitter':
                url = 'https://twitter.com/DerzaneMedya/';
                break;
            case 'freepik':
                url = 'https://www.freepik.com/photos/school';
                break;
            case 'discord':
                url = 'https://discord.gg/mRgU4HtNJg';
                break;
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }

    render() {
        return (
            <div className="Footer">
                <div className="FooterLogo">
                    <img src={derZaneLogo} height="100px" alt="derZane.com"/>
                </div>
                <div className="FooterClaim">
                    Bu web sitesi testler ve sorular üzerine tasarlanmıştır. Sitedeki tüm bilgiler, konusunda uzmanlaşmış güvenilir profesyonel kaynaklardan elde edilmektedir. Gizlilik ve veri koruma politikası uyarınca bu web sitesinden herhangi bir bilgi kopyalanması ve dağıtılmasına izin verilmez.        
                </div>
                {
                this.props.account.loggedIn ?
                <div className="FooterCenter">
                    <div className="FooterCenterHeader">
                        KEŞFET
                    </div>
                    <div>
                        <Link className="FooterCenterLink" to="/sik-sorulan-sorular">Sık Sorulan Sorular</Link>
                    </div>
                </div> : null
                }
                <div className="FooterRight">
                    <div className="FooterLinks">
                        <img className="FooterLink" src={facebook} alt="Facebook" height='50' onClick={() => this.handleClick('facebook')} />
                        <img className="FooterLink" src={instagram} alt="Instagram" height='50' onClick={() => this.handleClick('instagram')} />
                        <img className="FooterLink" src={discord} alt="LinkeIn" height='50' onClick={() => this.handleClick('discord')} />
                        <img className="FooterLink" src={twitter} alt="Twitter" height='50' onClick={() => this.handleClick('twitter')} />
                    </div>
                    <div className="FooterCredits"
                        onClick={() => this.handleClick('freepik')}>
                        Resimlerin kaynağı freepik.com
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Footer);