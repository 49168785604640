import { KONU } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_KONU = { 
    sinifId: '',
    dersId: '',
    konuId: '',
    konuAdi: ''
}

const konu = (state = { konular: [DEFAULT_KONU] }, action) => {
    switch(action.type) {
        case KONU.FETCH:
            return { ...state, status: fetchStates.fetching };
        case KONU.FETCH_ERROR:
            return { ...state, status: fetchStates.error, message: action.message };
        case KONU.FETCH_SUCCESS:
            return { ...state, status: fetchStates.success, konular: action.konular };
        default:
            return state;
    }
}

export default konu;