import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';
import DersKonulari from './DersKonulari';
import DersListele from './DersListele';
import { fetchKonu } from './actions/konu';
import { fetchDersSoruListele, fetchDersSoruFromSoruId } from './actions/dersSoru';
import SoruEditor from './SoruEditor';
import SoruGoster from './SoruGoster';

class DersSorulari extends Component {
    state = {
        konuId: 'H',
        altKonuId: 'H',
        kategoriId: 'H',
        etiket: '',
        kaynak: '',
        soruEditorAcik: false,
        soruGosterAcik: false,
        soru: undefined
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = 'Admin' + this.props.dersId + 'Sorular: ' + this.props.account.email;
        this.props.invokeLogger({ id, text });

        this.props.fetchKonu(id, '8', this.props.dersId );
        this.props.fetchDersSoruListele({
            id,
            sinifId: '8',
            dersId: this.props.dersId,
            konuId: this.state.konuId,
            altKonuId: this.state.altKonuId,
            kategoriId: this.state.kategoriId,
            etiket: this.state.etiket,
            kaynak: this.state.kaynak,
            soruSayisi: 999
        });
        window.scrollTo(0, 0);
    }

    konuSecildi = (konuId, altKonuId, kategoriId, etiket, kaynak) => {
        this.setState({ konuId, altKonuId, kategoriId, etiket, kaynak });
        let id = this.props.account.id;
        let dersId = this.props.dersId;

        this.props.fetchDersSoruListele({
            id,
            sinifId: '8',
            dersId,
            konuId,
            altKonuId,
            kategoriId,
            etiket,
            kaynak,
            soruSayisi: 999
        });
    }

    soruEditor = (soru) => {
        const id = this.props.account.id;
        const sinifId = '8';
        this.props.fetchDersSoruFromSoruId({
            id,
            sinifId,
            dersId: this.props.dersId,
            soruId: soru.soruId
        });
        this.setState({ soruEditorAcik: true, soru });
    }

    soruGoster = (soru) => {
        const id = this.props.account.id;
        const sinifId = '8';
        this.props.fetchDersSoruFromSoruId({
            id,
            sinifId,
            dersId: this.props.dersId,
            soruId: soru.soruId
        });
        this.setState({ soruGosterAcik: true, soru });
    }

    geriDon = () => {
        this.setState({
            soruEditorAcik: false,
            soruGosterAcik: false,
            soru: undefined
        });
    }

    yeniSoru = (konuId, altKonuId, kategoriId, etiket, kaynak) => {
        this.setState({
            konuId,
            altKonuId,
            kategoriId,
            etiket,
            kaynak,
            soruEditorAcik: true,
            soru: undefined
        });
    }

    render() {
        const dersler = this.props.ders.dersler;
        const ders = dersler.find(ders => { return ders.dersId === this.props.dersId });
        const { konuId, altKonuId, kategoriId, etiket, kaynak } = this.state;

        return (
            <div>
                <HeaderSub header={'SB Yönetici - ' + ders.dersId} />
                {
                    this.state.soruGosterAcik ?
                    <SoruGoster
                        soruAvatarAcik={true}
                        soru={this.state.soru}
                        sinifId='8'
                        dersId={ders.dersId}
                        dersAdi={ders.dersAdi}
                        konuId={konuId}
                        altKonuId={altKonuId}
                        kategoriId={kategoriId}
                        etiket={etiket}
                        kaynak={kaynak}
                        geriDon={this.geriDon}
                    />
                    :
                    this.state.soruEditorAcik ?
                    <SoruEditor
                        soruAvatarAcik={false}
                        soru={this.state.soru}
                        sinifId='8'
                        dersId={ders.dersId}
                        dersAdi={ders.dersAdi}
                        konuId={konuId}
                        altKonuId={altKonuId}
                        kategoriId={kategoriId}
                        etiket={etiket}
                        kaynak={kaynak}
                        geriDon={this.geriDon}
                    />
                    :
                    <div>
                        <DersKonulari
                            sinifId='8'
                            dersId={ders.dersId}
                            dersAdi={ders.dersAdi}
                            konuId={konuId}
                            altKonuId={altKonuId}
                            kategoriId={kategoriId}
                            etiket={etiket}
                            kaynak={kaynak}
                            konuSecildi={this.konuSecildi}
                            yeniSoru={this.yeniSoru}
                        />
                        <DersListele
                            sinifId='8'
                            dersId={ders.dersId}
                            dersAdi={ders.dersAdi}
                            soruEditor={this.soruEditor}
                            soruGoster={this.soruGoster}
                        />
                    </div>
            }
            </div>
        )
    }
}

export default connect(
    ({ account, ders, konu, altkonu }) => ({ account, ders, konu, altkonu }),
    { invokeLogger, fetchKonu, fetchDersSoruListele, fetchDersSoruFromSoruId }
)(DersSorulari);